import { PageElement, html, formCss } from 'Components';
import { UrlParams, Fetcher, Lang } from 'Utils';
import App from 'App';
import { diff } from 'just-diff';
import merge from 'deepmerge';

class DrawerForm extends PageElement {
  static get styles() {
    return [
      super.styles,
      formCss
    ]
  }

  static get properties() {
    return {
      item: { type: Object },
      pendingChanges: { type: Boolean },
      debug: { type: Boolean },
    }
  }

  static get translations() {
    return [
      super.translations,
      {
        english: {
          translation: {
            add: 'Add',
            edit: 'Edit',
            cancel: 'Cancel',
            save:'Save',
          },
        },
        french: {
          translation: {
            add: 'Ajouter',
            edit: 'Modifier',
            cancel: 'Annuler',
            save:'Sauvegarder',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.item = null;
    this.itemTitleField = 'title';
    this.pendingChanges = false;
    this.compareFields = this.compareFields.bind(this);
    this.onTranslated = this.onTranslated.bind(this);
    this.debug = false;
  }

  visibleCallback() {
    super.visibleCallback();
    window.addEventListener('translated', this.onTranslated);
  }

  hiddenCallback() {
    super.hiddenCallback();
    window.removeEventListener('translated', this.onTranslated);
  }

  onTranslated() {
    this.compareFields();
  }

  compareFields() {
    // reset reportValidity etc
    this.fields.forEach((el) => {
      if (el.setCustomValidity) {
        el.setCustomValidity('');
        el.reportValidity();
      }
      if (el.textarea?.setCustomValidity) {
        el.textarea.setCustomValidity('');
        el.reportValidity();
      }
    });

    if (this.mode === 'add') {
      this.modal.pendingChanges = this.pendingChanges = false;
      return;
    }

    clearTimeout(this.timerCompareField);
    this.timerCompareField = setTimeout(() => {
      this._log.debug('compareFields: comparing fields');
      const data = this.getFieldsValue();
      const result = diff(data, this.initialData);
      this._log.warn('compareFields: compare result', result);

      if (result.length === 0) {
        this.modal.pendingChanges = this.pendingChanges = false;
        this.modal.disableButtons();
      } else {
        this.modal.pendingChanges = this.pendingChanges = true;
        this.modal.resetButtons();
      }
    }, 100);
  }

  async updated(changedProperties) {
    if (changedProperties.has('item')) {
      if (!this.item) return;
      this.show();
      if (this.item._id) {
        await this.edit();
      } else {
        await this.add();
      }
    }
  }

  _lookupField(name, lg) {
    lg = lg || App.config.lgMain;
    const q = `[name="${name}"][lg="${lg}"]`;
    return this._recursiveQuerySelector(this.modal, q);
  }

  _recursiveQuerySelector(root, selector) {
    if (!root) return null;

    // Vérifier si l'élément courant correspond au sélecteur
    const found = root.querySelector(selector);
    if (found) return found;

    // Rechercher de manière récursive dans les shadow roots
    const elements = root.querySelectorAll('*');
    for (const el of elements) {
      if (el.shadowRoot) {
        const shadowFound = this._recursiveQuerySelector(el.shadowRoot, selector);
        if (shadowFound) return shadowFound;
      }
    }

    return null;
  }

  getItemTitle() {
    // override me ?
    return Lang.lookup(this.item, this.itemTitleField);
  }

  openTab() {
    const tab = UrlParams.get('tab');
    const tabGroup = this.shadowRoot.querySelector('sl-tab-group');

    if (tab && tabGroup) {
      setTimeout(() => {
        const tabGroup = this.shadowRoot.querySelector('sl-tab-group');
        if (tabGroup) {
          console.log('openTab', tab);
          tabGroup.show(tab);
        }
      }, 100);
    }
  }

  async show() {
    this.openTab();

    //  update url
    this.item._id ? UrlParams.set(this.urlVar, this.item._id) : UrlParams.del(this.urlVar);
    this.modal = this.shadowRoot.querySelector('modal-drawer');

    // disable form submit
    this.form = this.shadowRoot.querySelector('form');
    if (this.form) {
      this.form.addEventListener('submit', (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
      });
    }

    this.fields = this.getFields({ from:'show' });
    this.modal.show();
    await this.updateComplete;
    
    this.listenFields();
    this._log.debug('show: initial data', this.initialData);
    
    // specific
    this.markdownEditor = this.shadowRoot.querySelector('markdown-editor');
    this.imageUploader = this.shadowRoot.querySelector('image-uploader');
    if (this.imageUploader) this.imageUploader.show();

    setTimeout(() => {
      this.initialData = this.getFieldsValue();
    }, 200);
  }

  async add() {
    this.mode = 'add';
    this.resetFields();
    const itemTitle = this.getItemTitle({ mode: 'add' });
    this.modal.label = html`${this._tl('add')}${itemTitle ? `: ${itemTitle}` : ''}`;
    this.modal.resetButtons();
    const firstTab = this.qs('tab-group')?.querySelector('sl-tab');
    if (firstTab) firstTab.click();
  }

  async edit() {
    this.mode = 'edit';
    const itemTitle = this.getItemTitle({ mode: 'edit' });
    this.modal.label = html`${this._tl('edit')}${itemTitle ? `: ${itemTitle}` : ''}`;
    this.forceFormFieldsValues();
    this.modal.disableButtons();
  }

  forceFormFieldsValues() {
    // Specific fields i have problem with render()
    if (!this.fields || !this.fields.length) return;
    this.fields.forEach((el) => {
      const tagName = el.tagName.toLowerCase();
      if (tagName === 'sl-switch') {
        //.checked=${this.item.promo} => ${this.item.promo ? 'checked' : ''} does not work so ..
        el.checked = this.item[el.name];
        return;
      }
    });
  }

  toggleFormFields(enable) {
    this.fields.forEach((el) => {
      el.disabled = !enable;
      if (el.textarea) el.textarea.disabled = !enable;
    });
  }

  resetFields() {
    //console.log('resetFields');
    this.fields.forEach((el) => {
      el.value = el.getAttribute('defaultValue');
    });
  }

  listenFields() {
    this.fields.forEach((el) => {
      if (el.hasListener) return;
      el.addEventListener('input', ev => {
        if (ev.target.tagName === 'SL-INPUT') {
          // test validity
          if (!ev.target.checkValidity()) {
            ev.target.setCustomValidity(ev.target.validationMessage);
          } else {
            ev.target.setCustomValidity('');
          }
          ev.target.reportValidity();
        }
        this.compareFields(ev);
      });
      el.addEventListener('sl-change', this.compareFields );
      el.hasListener = true;
      //@TODO: submit on enter
    });
  }

  cleanImageUrls(images) {
    if (images && images.length) {
      for (const image of images) {
        if (image.src.includes('imgp')) {
          image.src = image.src.replace(`${STATIC_PUBLIC_URL}/imgp/[^/]+/`, '/');
          image.src = image.src.split('@')[0];
        }
      }
    }
  }

  getFields(opts = {}) {

    const fields = Array.from(this.modal.getElementsByTagName('*')).filter(el => {
      const tn = el.tagName.toLowerCase();
      if (tn.startsWith('select-')) return el;
      if (tn.startsWith('sl-input')) return el;
      if (tn.startsWith('sl-select')) return el;
      if (tn.startsWith('sl-textarea')) return el;
      if (tn.startsWith('sl-switch')) return el;
      if (tn.startsWith('sl-color-picker')) return el;
      if (tn.endsWith('editor')) return el;
      if (tn.endsWith('uploader')) return el;
    });


    // order this.fields, hidden should be first because
    // it contains original values for multilingue fields
    this.fields = Array.from(fields).sort((a, b) => {
      if (a.type === 'hidden') return -1;
      if (b.type === 'hidden') return 1;
      return 0;
    });

    this._log.debug('getFields', opts.from, Date.now(), this.fields.length);
    return this.fields;
  }
  
  cleanSpecialChars(str) {
    if (typeof str !== 'string') return str;
    return str.replace(/"/g, '');
  }

  getFieldsValue() {
    let data = {};
    let tabGroupLg;
    this.fields = this.getFields({ from: 'getFieldsValue' });

    this.fields.forEach((el) => {

      if (el.getAttribute('exclude_change')) return;

      let value = this.cleanSpecialChars(el.value);
      const tagName = el.tagName.toLowerCase();

      if (el.type === 'number') {
        value = value ? parseFloat(value) : null;
      } else if (tagName === 'sl-switch') {
        value = el.checked ? true : false;
      } else if (tagName === 'markdown-editor') {
        value = el.textarea.value;
      } else if (tagName === 'image-uploader') {
        value = [ ...el.value || [] ]
      } else if (tagName === 'grapesjs-mjml-editor') {
        value = { ... el.value || {} };
      } else if (tagName.match(/^select-/)) {
        if (el.multiple) {
          value = [ ...el.items || []];
        } else {
          value = el.value;
        }
      } else if (el.type === 'hidden') {
        value = this.cleanSpecialChars(el.value);
      }

      const multilingue = el.getAttribute('lg') || el.lg;
      if (multilingue) {
        if (typeof data[el.name] !== 'object') {
          this._log.debug('getFieldsValue: multilingue: init', el.name, multilingue, value, data[el.name]);
          data[el.name] = {};
        } else {
          this._log.debug('getFieldsValue: multilingue: update', el.name, multilingue, value, data[el.name]);
        }
        data[el.name][multilingue] = this.cleanSpecialChars(value);
      } else {
        if (data[el.name]) {
          this._log.error('getFieldsValue: duplicate field', el.name, el);
          throw new Error(`duplicate field ${el.name}`);
        }
        data[el.name] = this.cleanSpecialChars(value);
      }

      if (!tabGroupLg && el.parentNode.tagName === 'TAB-GROUP-LG') {
        tabGroupLg = el.parentNode;
      }

      this._log.debug('getFieldsValue', el.name, value);
    });

    if (tabGroupLg) {
      data = merge(tabGroupLg.item, data, { arrayMerge: this.overwriteMerge });
    }

    this.cleanImageUrls(data.images);

    return data;
  }

  overwriteMerge(destinationArray, sourceArray/*, options*/) {
    return sourceArray;
  }

  async onHide() {
    UrlParams.del(this.urlVar);
    this.imageUploader && this.imageUploader.hide();
  }

  // eslint-disable-next-line no-unused-vars
  handleSubmitError(input, response) {
    // override me
    console.warn('handleSubmitError', 'please override me');
  }

  async handleSubmit() {
    this.modal.loadingButtons();
    this.toggleFormFields(false);
    
    const data = this.getFieldsValue({ from:'handleSubmit' });

    const response = await Fetcher.postOrPut(this.apiEndpoint, data, this.item?._id);
    this.modal.pendingChanges = this.pendingChanges = false;
    if (!response) {
      this.toggleFormFields(true);
      this.modal.resetButtons();
      return;
    }

    if (response.error === 'EVALIDATION_FAILED') {
      this.toggleFormFields(true);

      // find field in this.fields, lg is optional
      let input;
      if (response.lg) {
        input = this.fields.filter(el => el.name === response.field && el.lg === response.lg)[0];
      } else {
        input = this.fields.filter(el => { return el.name === response.field; })[0];
      }

      if (!input) {
        this._log.error('handleSubmitError: input not found', response);
        this.modal.resetButtons();
        return;
      }

      const tabPanel = input.closest('sl-tab-panel');
      tabPanel.closest('sl-tab-group').show(tabPanel.name);
      const tabPanelParent = tabPanel.closest('sl-tab-group').closest('sl-tab-panel');
      if (tabPanelParent) {
        tabPanelParent.closest('sl-tab-group').show(tabPanelParent.name);
      }
      await this.updateComplete;
      this.handleSubmitError(input, response);
      this.modal.resetButtons();
      return;
    }

    this.toggleFormFields(true);
    this.modal.resetButtons();
    this.modal.hide();

    if (this.eventUpdated) {
      const event = new CustomEvent(this.eventUpdated, { detail: response.data });
      window.dispatchEvent(event);
    } else {
      this._log.warn('handleSubmit: eventUpdated not defined');
    }
  }

}

export default DrawerForm;